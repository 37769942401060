import React from 'react'
import Navbar from './navBar'
import '../../styles/global.css'
import Footer from './Footer'

export default function Layout({children}) {
  return (
    <div className='layout'>
        <Navbar />
        <div className="contents">
            {children}
        </div>
        <Footer />
    </div>
  )
}
